// IMlCateListSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Base_url from "../../Base_url";
import axios from "axios";
import toast from "react-hot-toast";
import { fetchIMLListAsync } from "./IMLSlice";
import { HospitalItems } from "./OnlyHospital/HospitalItem";


const token = localStorage.getItem("token");

export const bulkupload = createAsyncThunk(
  "item/bulkupload",
  async (dataToSend , { rejectWithValue, dispatch }) => {
    try {
      // Create FormData object
      const { selectedFile } = dataToSend
      const formData = new FormData();
      formData.append("excel_file", selectedFile);

      const response = await axios.post(
        `${Base_url}/api/item/bulk-upload`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data", // Set content type to multipart form-data
          },
        }
      );
      toast.success(response.data?.message);
      dispatch(fetchIMLListAsync(dataToSend));
      return response.data.data;
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An error occurred while uploading."
      );
      throw error;
    }
  }
);


export const bulkuploadHosital = createAsyncThunk(
  "itemhos/bulkuploads",
  async (dataToSend , { rejectWithValue, dispatch }) => {
    try {
      // Create FormData object
      const { selectedFile } = dataToSend;
      const formData = new FormData();
      formData.append("excel_file", selectedFile);

      const response = await axios.post(
        `${Base_url}/api/hospital-item/bulk-upload`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data", // Set content type to multipart form-data
          },
        }
      );
      toast.success(response.data?.message);
      dispatch(HospitalItems(dataToSend));
      return response.data.data;
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An error occurred while uploading."
      );
      throw error;
    }
  }
);



export const bulkuploadHos = createAsyncThunk(
  "itemhospital/bulkuploads",
  async (dataToSend , { rejectWithValue, dispatch }) => {
    try {
      // Create FormData object
      const { selectedFile } = dataToSend
      const formData = new FormData();
      formData.append("excel_file", selectedFile);

      const response = await axios.post(
        `${Base_url}/api/item/hospital/bulk-upload`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "multipart/form-data", 
          },
        }
      );
      dispatch(fetchIMLListAsync(dataToSend));
      toast.success(response.data?.message);
      return response.data.data;
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An error occurred while uploading."
      );
      throw error;
    }
  }
);


export const bulkCategorydelete = createAsyncThunk(
  "itembulkdelete/bulkdelete",
  async (dataToSend , { rejectWithValue, dispatch }) => {
    try {

      const response = await axios.get(
        `${Base_url}/api/item/diagnostic/bulk-delete`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      toast.success(response.data?.message);
      dispatch(fetchIMLListAsync(dataToSend));
      return response.data.data;
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An error occurred while uploading."
      );
      throw error;
    }
  }
);


export const bulkHosCategorydelete = createAsyncThunk(
  "itembulkhosdelete/bulkhosdelete",
  async (dataToSend , { rejectWithValue, dispatch }) => {
    try {

      const response = await axios.get(
        `${Base_url}/api/item/diagnostic/hospital/bulk-delete`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      toast.success(response.data?.message);
      dispatch(fetchIMLListAsync(dataToSend));
      return response.data.data;
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An error occurred while uploading."
      );
      throw error;
    }
  }
);


export const bulkHosCategorydeleteOnly = createAsyncThunk(
  "itembulkhosdeleteonly/bulkhosdeleteonly",
  async (dataToSend , { rejectWithValue, dispatch }) => {
    try {

      const response = await axios.get(
        `${Base_url}/api/hospital-item/bulk-delete`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      toast.success(response.data?.message);
      dispatch(HospitalItems(dataToSend));
      return response.data.data;
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An error occurred while uploading."
      );
      throw error;
    }
  }
);


// diagnostic selectd item delete 

export const SelectedcategoriesDelete = createAsyncThunk(
  "tcCategoriesdleteseleted/deleteselectedCategories",
  async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      const { diagnostic_tests } = dataToSend;
      const response = await axios.post(
        `${Base_url}/api/item/diagnostic/selected/tests/delete`,
        { diagnostic_tests },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      // Dispatch the second API call after the first one is successful
      dispatch(fetchIMLListAsync(dataToSend));

      return response.data;
    } catch (error) {
      // Handle any errors that might occur during the API request
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// hospital selected item delete 

export const SelectedHoscategoriesDelete = createAsyncThunk(
  "Itemdleteseleted/deleteselectedItem",
  async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      const { hospital_beds } = dataToSend;
      const response = await axios.post(
        `${Base_url}/api/item/diagnostic/hospital/selected/beds/delete`,
        { hospital_beds },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      // Dispatch the second API call after the first one is successful
      dispatch(fetchIMLListAsync(dataToSend));

      return response.data;
    } catch (error) {
      // Handle any errors that might occur during the API request
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);


// only hospital selected items deleted

export const SelectedOnlyHoscategoriesDelete = createAsyncThunk(
  "ItemdleteseletedonlyHos/deleteselectedItemonlyhos",
  async (dataToSend, { dispatch, rejectWithValue }) => {
    try {
      const { hospital_beds } = dataToSend;
      const response = await axios.post(
        `${Base_url}/api/hospital-item/hospital/selected/beds/delete`,
        { hospital_beds },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      // Dispatch the second API call after the first one is successful
      dispatch(HospitalItems(dataToSend));

      return response.data;
    } catch (error) {
      // Handle any errors that might occur during the API request
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);


const bulkItemUpload = createSlice({
  name: "bulkuploads",
  initialState: {
    bulkValue: [],
    bulkValueHospital: [],
    bulkhospitalItem:[],
    status: "idle",
    error: "",
  },

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(bulkupload.pending, (state) => {
        state.status = "loading";
      })
      .addCase(bulkupload.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.bulkValue = action.payload;
      })
      .addCase(bulkupload.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })


      .addCase(bulkuploadHosital.pending, (state) => {
        state.status = "loading";
      })
      .addCase(bulkuploadHosital.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.bulkValueHospital = action.payload;
      })
      .addCase(bulkuploadHosital.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

      
      .addCase(bulkuploadHos.pending, (state) => {
        state.status = "loading";
      })
      .addCase(bulkuploadHos.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.bulkhospitalItem = action.payload;
      })
      .addCase(bulkuploadHos.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })
     // diagnotics bulk delete
      .addCase(bulkCategorydelete.pending, (state) => {
        state.status = "loading";
      })
      .addCase(bulkCategorydelete.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(bulkCategorydelete.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

      // hospital bulk delete 

      .addCase(bulkHosCategorydelete.pending, (state) => {
        state.status = "loading";
      })
      .addCase(bulkHosCategorydelete.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(bulkHosCategorydelete.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

      // only hospital bulk delete

      .addCase(bulkHosCategorydeleteOnly.pending, (state) => {
        state.status = "loading";
      })
      .addCase(bulkHosCategorydeleteOnly.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(bulkHosCategorydeleteOnly.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

      // selected item delete for diagnostic

      .addCase(SelectedcategoriesDelete.pending, (state) => {
        state.status = "loading";
      })
      .addCase(SelectedcategoriesDelete.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(SelectedcategoriesDelete.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

      // selected item delete for hospital item

      .addCase(SelectedHoscategoriesDelete.pending, (state) => {
        state.status = "loading";
      })
      .addCase(SelectedHoscategoriesDelete.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(SelectedHoscategoriesDelete.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })


      // selected item delete for only hospital item

      .addCase(SelectedOnlyHoscategoriesDelete.pending, (state) => {
        state.status = "loading";
      })
      .addCase(SelectedOnlyHoscategoriesDelete.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(SelectedOnlyHoscategoriesDelete.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "An error occurred";
      })

  },
});

export default bulkItemUpload.reducer;