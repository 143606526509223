import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
// import "./BookingsHistory.css";
// use for tab part start here //
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { InputLabel, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { fetchTCTransactionsListAsync } from "../Store/Features/Tctransaction";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { CSVLink } from "react-csv";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CircularProgress from "@mui/material/CircularProgress";
// import DatePicker from "react-datepicker";


import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from 'dayjs';
import { debitTCTransaction, fetchHospitalTransation, settlementCsv, settlementTransation } from "../Store/Features/OnlyHospital/HospitalTransation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const payment = [
  {
    value: "UPI",
    label: "UPI",
  },
  {
    value: "NEFT",
    label: "NEFT",
  },
  {
    value: "CARD",
    label: "CARD",
  },
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// use for tab part end here //

const OnlyHosSettlement = () => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = useState(0);
  const [filter, setFilter] = useState(7);
  const [startDate, setStartDate] = useState(null); // Add startDate state
  const [endDate, setEndDate] = useState(null);
  const [searchClicked, setSearchClicked] = useState(false);
  const [reportFile, setReportFile] = useState(null);

  const dispatch = useDispatch();

  const settlelmentList =
    useSelector((state) => state.hosTransation.onlyHospitalSettlememt?.data) || [];

  const settlelmentcsv =
  useSelector((state) => state.hosTransation.onlyHoscsv?.data) || [];


    useEffect(()=>{
      dispatch(settlementCsv())
    },[])

  const status = useSelector((state) => state.hosTransation?.status);

  const lastClosingBalance =
    settlelmentList && settlelmentList.length > 0
      ? settlelmentList[0].closing
      : 0;

  useEffect(() => {
    // Dispatch API call whenever startDate or endDate changes
    if (startDate == null && endDate == null) {
      const formattedStartDate = startDate?.toISOString().split("T")[0];
      const formattedEndDate = endDate?.toISOString().split("T")[0];
      const dataToSend = {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        
      }
      dispatch(
        settlementTransation(dataToSend)
      );
    }
  }, [dispatch, startDate, endDate, searchClicked]);

  const handleDateChange = (newDates) => {
    const [start, end] = newDates;
    const formattedStartDate = dayjs(newDates[0]).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(newDates[1]).format('YYYY-MM-DD');
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    const dataToSend = {
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      
    }
    dispatch(
      settlementTransation(dataToSend)
    );
    console.log("Selected date range:", formattedStartDate, formattedEndDate);
  };


  const [summaryValues, setSummaryValues] = useState({
    totalAmountPaid: "",
    remark: "",
    paymentMode: "", // Add paymentMode
  });

  const handleInputChange = (fieldName) => (event) => {
    setSummaryValues((prevValues) => ({
      ...prevValues,
      [fieldName]: event.target.value,
    }));
  };

  const handlePaymentModeChange = (event) => {
    setSummaryValues((prevValues) => ({
      ...prevValues,
      paymentMode: event.target.value,
    }));
  };

  const handleSubmit = () => {
    if (!summaryValues.totalAmountPaid || !summaryValues.paymentMode || !reportFile) {
      toast.error("Please fill in all required fields");
      return;
    }else if(lastClosingBalance < summaryValues.totalAmountPaid){
      toast.error(`payable amount not greater then ${lastClosingBalance}`)
      return;
    }
    const debitData = {
      // "booking_id": "string",
      debit: parseFloat(summaryValues.totalAmountPaid),
      remarks: summaryValues.remark,
      payment_mode: summaryValues.paymentMode,
      attachment: reportFile,
    };
    dispatch(debitTCTransaction(debitData));
    // console.log("Submitting values:", summaryValues);
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const filterDay = (newValue) => {
    setFilter(newValue);

    // Calculate start and end dates based on the selected filter
    const currentDate = new Date();
    const endDate = currentDate.toISOString().split("T")[0]; // End date is today

    let startDate = new Date(); // Start date
    startDate.setDate(currentDate.getDate() - newValue); // Subtract newValue days from current date
    startDate = startDate.toISOString().split("T")[0];

    // Dispatch API call with the calculated start and end dates
    const dataToSend = {
      start_date: startDate,
      end_date: endDate,

    }
    dispatch(
      settlementTransation(dataToSend)
    );
  };


  const handleReset = () => {
    window.location.reload();
  }


  const excludedKeys = [
    "_id",
    "partnerId",
    "attachment",
    "booking_details",
    "partner_details",
  ];

  console.log(settlelmentcsv,'settlelmentcsv')

  const filteredData = settlelmentcsv.map((item) => {
    const filteredItem = { ...item };
    excludedKeys.forEach((key) => delete filteredItem[key]);
    return filteredItem;
  });

  return (
    <>
      {/*********** body area start here ************/}
      <Card>
        <CardContent>
        <Grid container alignItems="baseline">
          <Grid item xs={11}>
            <h2 className="m0"> Hospital TC Settlement </h2>
          </Grid>
          <Grid item xs={1}>
          <Button variant="contained" size="small" onClick={handleReset}>Reset</Button>
          </Grid>
          </Grid>
          {/* header part work start */}
          <Grid className="mb10" container alignItems="center">
            {/* searc start */}
            <Grid item xs={3} sx={{ marginBottom: "45px" }}>
              <div className="searchBox" style={{ position: "absolute" }}>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search....."
                  inputProps={{ "aria-label": "Search" }}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  {" "}
                  <SearchIcon />{" "}
                </IconButton>
              </div>
            </Grid>
            {/* search end */}
            {/* search box start */}
            <Grid item md={3}>
              <div className="dayFilter">
                <div
                  className={filter === 7 ? "activeFilter" : "inactive"}
                  onClick={(e) => filterDay(7)}
                >
                  Last 7 Days
                </div>
                <div
                  className={filter === 15 ? "activeFilter" : "inactive"}
                  onClick={(e) => filterDay(15)}
                >
                  Last 15 Days
                </div>
                <div
                  className={filter === 30 ? "activeFilter" : "inactive"}
                  onClick={(e) => filterDay(30)}
                >
                  Last 30 Days
                </div>
              </div>
            </Grid>

            <Grid item md={3}>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateRangePicker"]}>
                    <DateRangePicker
                      onChange={handleDateChange} // Add onChange function
                      localeText={{ start: "start-date", end: "end-date" }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </Grid>

            <Grid md={2}>
              <Button variant="contained" sx={{float:'right', background:'#00c2c1', color:'white'}} onClick={handleOpen}>
                pay now
              </Button>
            </Grid>

            <Grid md={1}>
              <CSVLink
                style={{
                  color: "#43a047",
                  // background: "#00c2c1",
                  borderRadius: "10px",
                  padding: "4px",
                  marginRight: "7px",
                  display: "flex",
                  justifyContent: "center",
                }}
                data={filteredData}
              >
                <span>Export</span>
                <SaveAltIcon />
              </CSVLink>
            </Grid>
          </Grid>
          {/* header part work end */}

          {/* tab part work start here */}
          <Box className="tabBox" sx={{ width: "100%" }}>
            {/* tab item (1) start */}
            <CustomTabPanel
              value={value}
              index={0}
              style={{ padding: "0!important" }}
            >
              {/* table work start here */}
              <Paper className="customTable" sx={{ width: "100%" }}>
                <TableContainer sx={{ maxHeight: 385 }}>
                  <Table>
                    <TableHead>
                      <TableRow
                        sx={{
                          backgroundColor: "red",
                          // borderBottom: "2px solid black",
                          "& th": {
                            fontSize: "0.875rem",
                            color: "#00c2c1",
                          },
                        }}
                      >
                        <TableCell>Date of Payment</TableCell>
                        <TableCell>Opening (₹)</TableCell>
                        <TableCell>Credit (₹)</TableCell>
                        <TableCell>Debit (₹)</TableCell>
                        <TableCell>Closing (₹)</TableCell>
                        <TableCell>Mode of Payment</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Remarks</TableCell>
                        {/* <TableCell>Action</TableCell>
                        <TableCell>TC Confirmation</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {status === "loading" ? ( // Render CircularProgress when status is 'loading'
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      ) : Array.isArray(settlelmentList) &&
                        settlelmentList.length > 0 ? ( // Check if settlelmentList is an array and has data
                        settlelmentList.map((item, index) => (
                          <TableRow key={item._id} hover
                            className={index === 0 ? 'highlight-row' : ''}>
                            <TableCell>
                              {" "}
                              {item?.payment_date
                                ? new Date(item.payment_date).toLocaleDateString(
                                  "en-GB"
                                )
                                : "-"}
                            </TableCell>
                            <TableCell>{item?.opening}</TableCell>
                            <TableCell>{item?.credit}</TableCell>
                            <TableCell>{item?.debit}</TableCell>
                            <TableCell>{item?.closing}</TableCell>
                            <TableCell>{item?.payment_mode}</TableCell>
                            <TableCell>{item?.status}</TableCell>
                            <TableCell>{item?.remarks}</TableCell>
                            {/* <TableCell>Submit</TableCell> */}
                            {/* <TableCell>Confirmed</TableCell> */}
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8} align="center">
                            No Data Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              {/* table work end here */}
            </CustomTabPanel>
          </Box>
          {/* tab part work end here */}
        </CardContent>
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Grid item xs={8}>
                <Card>
                  <CardContent>
                    <h2>Pay Now</h2>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          <TableRow sx={{ "td,th": { border: 1 } }}>
                            <TableCell>
                              <b>Total Due</b>
                            </TableCell>
                            <TableCell>{lastClosingBalance}</TableCell>
                          </TableRow>
                          <TableRow sx={{ "td,th": { border: 1 } }}>
                            <TableCell>
                              <b>Paid Amount</b>
                            </TableCell>
                            <TableCell>
                              <TextField
                                type="Number"
                                value={summaryValues?.totalAmountPaid}
                                onChange={handleInputChange("totalAmountPaid")}
                                id="outlined-required"
                                label="Paid Amount"
                                required
                                defaultValue="Hello World"
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ "td,th": { border: 1 } }}>
                            <TableCell>
                              <b>Balance Due</b>
                            </TableCell>
                            <TableCell>
                              {lastClosingBalance -
                                summaryValues?.totalAmountPaid}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ "td,th": { border: 1 } }}>
                            <TableCell>
                              <b>Payment Mode</b>
                            </TableCell>
                            <TableCell>
                              <FormControl fullWidth>
                                <InputLabel htmlFor="outlined-select-currency">
                                  Payment Mode
                                </InputLabel>
                                <Select
                                  id="outlined-select-currency"
                                  value={summaryValues.paymentMode}
                                  onChange={handlePaymentModeChange}
                                  label="Payment Mode"
                                  displayEmpty
                                >
                                  {payment.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </TableRow>

                          <TableRow sx={{ "td,th": { border: 1 } }}>
                            <TableCell>
                              <b>Remark</b>
                            </TableCell>
                            <TableCell>
                              <TextField
                                id="outlined-textarea"
                                label="Remark"
                                placeholder="Your Remark"
                                multiline
                                value={summaryValues.remark}
                                onChange={handleInputChange("remark")}
                              />
                            </TableCell>
                          </TableRow>

                          <TableRow hover sx={{ "td,th": { border: 1 } }}>
                            <TableCell sx={{ width: 175 }}>
                              <b>Attachment</b>
                            </TableCell>
                            <TableCell>
                              <input
                                type="file"
                                id="report-upload-input"
                                style={{ display: "none" }}
                                onChange={(e) => setReportFile(e.target.files[0])}
                              />
                              <label htmlFor="report-upload-input">
                                <Button
                                  variant="outlined"
                                  // color="warning"
                                  component="span"
                                  endIcon={<CloudUploadOutlinedIcon />}
                                >
                                  Upload
                                </Button>
                              </label>
                              {reportFile && (
                                <p>Selected file: {reportFile?.name}</p>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Button
                        className="mt20"
                        variant="contained"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                      <Button
                        className="mt20"
                        variant="outline"
                        style={{
                          float: "right",
                          background: "red",
                          color: "white",
                        }}
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </Button>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
            </Box>
          </Modal>
        </div>
      </Card>
      {/************ body area end here ************/}
    </>
  );
};

export default OnlyHosSettlement;